import React, { createContext, useCallback, useEffect, useState } from "react";

// third-party
import { dispatch, useSelector } from "../store/index";
import {
  setlogout,
  setLogin,
  setAuthRole,
  setLogoutStatus,
  setLoggingInStatus,
} from "../store/reducers/login";

// project import
import Loader from "../components/Loader";
import { KeyedObject } from "../types/root";
import { Auth0ContextType } from "../types/auth";
import { useAuth0 } from "@auth0/auth0-react";
import { useLazyGetOrgRolesListQuery } from "store/reducers/roles";
import { enqueueSnackbar } from "notistack";
import captureSentryError from "helper/captureSentryError";
import { checkEmployerSubDomain } from "helper/general";
import { getCookies, removeCookie, setCookies } from "helper/cookies";
import { OrgRoleListInfo } from "types/roles";

// ==============================|| AUTH0 CONTEXT & PROVIDER ||============================== //

const Auth0Context = createContext<Auth0ContextType | null>(null);

export const Auth0Provider = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const state = useSelector((state) => state.auth);
  const [params, setParams] = useState(localStorage.getItem('params'));

  const [fetch, { isLoading: loading, isError: error }] =
    useLazyGetOrgRolesListQuery();

  const {
    user,
    isAuthenticated,
    loginWithRedirect,
    logout: authLogout,
    getAccessTokenSilently,
    isLoading: authloading,
    error: authError
  } = useAuth0();

  const isLoading = authloading || loading;

  const setRole = (option: {
    EmployerID: string
    RoleName: string;
    RoleID: string;
    FirstName: string;
    LastName: string;
    HealthCheckToolDisable?: boolean;
    EmployerName?: string;
    EnableInvestmentAdvice?: boolean;
    EnableMortgageService?: boolean;
    EnableWillService?: boolean;
    EnableSnoop?: boolean;
    EnableWealthify?: boolean;
    EnableAvcwise?: boolean;
    PrivacyPolicyDocument?: boolean;
    SubDomain?: string;
    OrganisationType?: string;
  }) => {
    dispatch(
      setAuthRole({
        isLoggedIn: true,
        isInitialized: true,
        FirstName: option.FirstName,
        LastName: option.LastName,
        user: { ...user, ...option },
      })
    );
    setCookies(
      "EmployeeDetails",
      JSON.stringify({ ...user, ...option })
    );
    checkEmployerSubDomain(option?.SubDomain as string, option.RoleID, option.EmployerID);
  };

  const logout = useCallback(async() => {
    try {
    const subDomain = state.user?.SubDomain;

    const employerSubDomain = subDomain ? `https://${subDomain}${process.env.REACT_APP_AVC_DEFAULT_DOMAIN}` : process.env.REACT_APP_AVC_DOMAIN;
    dispatch(setLogoutStatus({ isLoggingOut: true }));
    dispatch(setlogout());
    if (params === "true")
      await authLogout({ logoutParams: { returnTo: window.location.origin } });
    else
      await authLogout({ logoutParams: { returnTo: `${employerSubDomain}/users/sign_out?logout=true` } });

    removeCookie("EmployeeDetails");
    const isLoggedOut = getCookies('isLoggedOut')

    if (isLoggedOut && state.user) {
      removeCookie('isLoggedOut')
    } else if (subDomain) {
      setCookies('isLoggedOut', true)
    }
    localStorage.removeItem("params");
    localStorage.removeItem("isConsentDialogShown");
    setParams(null);
    // dispatch(setLogoutStatus({ isLoggingOut: true }));
  } catch(error) {
    console.log("Logout Error", error)
  }
  }, [authLogout, state.user?.SubDomain]);

  useEffect(() => {
    const init = async () => {
      const employerSubDomain = state.user?.SubDomain ? `https://${state.user?.SubDomain}${process.env.REACT_APP_AVC_DEFAULT_DOMAIN}` : process.env.REACT_APP_AVC_DOMAIN;
      if (authError) {
        captureSentryError(authError, params ?? "")
        enqueueSnackbar(authError?.message, {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          autoHideDuration: 2000,
        }
        );

        if (params === "true") {
          authLogout({
            logoutParams: {
              returnTo: window.location.origin
            }
          });
        } else {
          authLogout({
            logoutParams: {
              returnTo: `${employerSubDomain}/users/sign_out?logout=true`
            }
          });
        }

        dispatch(setLogoutStatus({ isLoggingOut: false }));
        dispatch(setlogout());
        if (params === "true")
          authLogout({ logoutParams: { returnTo: window.location.origin } });
        else
          authLogout({ logoutParams: { returnTo: `${employerSubDomain}/users/sign_out?logout=true` } });
        removeCookie("EmployeeDetails");
        localStorage.removeItem("params");
        setParams(null);
        localStorage.removeItem("isConsentDialogShown");
      } else {
        if (error) return;
        if (state.isLoggingIn) {
          return
        }
        try {
          const isLoggedIn = isAuthenticated;
          dispatch(setLoggingInStatus({ isLoggingIn: true }));
          if (!isLoading) {
            if (isLoggedIn) {
              const redirectingOrgRoleId = getCookies('orgRoleId');
              const redirectingOrgId = getCookies('employerId');
              const isLoggedOut = getCookies('isLoggedOut')

              if (isLoggedOut) {
                logout()
                return;
              }

              const getUser = getCookies("EmployeeDetails") ?? "";
              const token = await getAccessTokenSilently();
              let subDomain = ''
              let employerId = ''
              let roleId = ''

              if (token) {
                const newState = {
                  isLoggedIn: true,
                  token,
                  user: {
                    id: user?.sub,
                    email: user?.email
                  },
                };

                dispatch(setLogin(newState));

                if (!getUser || (redirectingOrgRoleId && redirectingOrgId)) {
                  console.log("Get Org Roles Called")
                  const { data } = await fetch("");
                  const organisations = data?.data?.organisation;

                  if (organisations?.length === 1) {
                    const organisation = organisations![0];
                    subDomain = organisation.SubDomain!;
                    checkEmployerSubDomain(organisation.SubDomain as string)
                  }

                  const selectedOrgnisation = organisations?.find(org => org.EmployerID === redirectingOrgId)

                  if (selectedOrgnisation && redirectingOrgRoleId && redirectingOrgId) {
                    const {
                      EmployerID,
                      HealthCheckToolDisable,
                      EnableInvestmentAdvice,
                      EnableSnoop,
                      EnableMortgageService,
                      EnableWillService,
                      EnableAvcwise,
                      EnableWealthify,
                      PrivacyPolicyDocument,
                      SubDomain,
                      EmployerName,
                      OrganisationType
                    } = selectedOrgnisation;

                    const { FirstName, LastName } = data?.data as OrgRoleListInfo;

                    const { MMRoleID: RoleID, RoleName } = selectedOrgnisation?.Roles?.find(role => role.MMRoleID === redirectingOrgRoleId) || {};

                    setRole({
                      EmployerID,
                      FirstName,
                      LastName,
                      EmployerName,
                      HealthCheckToolDisable,
                      EnableInvestmentAdvice,
                      EnableMortgageService,
                      EnableWillService,
                      EnableAvcwise,
                      PrivacyPolicyDocument,
                      EnableSnoop,
                      EnableWealthify,
                      SubDomain,
                      RoleID: RoleID!,
                      RoleName: RoleName!,
                      OrganisationType
                    });

                    employerId = EmployerID;
                    roleId = RoleID!;
                    subDomain = SubDomain!;

                    removeCookie('orgRoleId')
                    removeCookie('employerId')
                    dispatch(setLoggingInStatus({ isLoggingIn: false }));
                  } else if (data?.data.singleLogin) {
                    const Employer = data?.data.organisation[0];
                    const EmployerID = Employer?.EmployerID;
                    const EmployerName = Employer?.EmployerName;
                    const RoleID = Employer?.Roles[0].MMRoleID;
                    const RoleName = Employer?.Roles[0].RoleName;
                    const singleLogin = data?.data.singleLogin;
                    const FirstName = data?.data?.FirstName;
                    const LastName = data?.data?.LastName;
                    const HealthCheckToolDisable = Boolean(Employer?.HealthCheckToolDisable);
                    const EnableInvestmentAdvice = Boolean(Employer?.EnableInvestmentAdvice);
                    const EnableMortgageService = Boolean(Employer?.EnableMortgageService)
                    const EnableWillService = Boolean(Employer?.EnableWillService)
                    const EnableAvcwise = Boolean(Employer?.EnableAvcwise)
                    const EnableSnoop = Boolean(Employer?.EnableSnoop);
                    const EnableWealthify = Boolean(Employer?.EnableWealthify)
                    const PrivacyPolicyDocument = Boolean(Employer?.PrivacyPolicyDocument);
                    const SubDomain = Employer?.SubDomain as string;
                    const OrganisationType = Employer?.OrganisationType;
                    employerId = EmployerID;
                    roleId = RoleID!;
                    subDomain = SubDomain;
                    dispatch(
                      setAuthRole({
                        isLoggedIn: true,
                        isInitialized: true,
                        FirstName: FirstName,
                        LastName: LastName,
                        user: {
                          ...user,
                          EmployerID,
                          RoleID,
                          RoleName,
                          EmployerName,
                          singleLogin,
                          HealthCheckToolDisable,
                          EnableInvestmentAdvice,
                          EnableMortgageService,
                          EnableWillService,
                          EnableAvcwise,
                          EnableWealthify,
                          PrivacyPolicyDocument,
                          EnableSnoop,
                          SubDomain,
                          OrganisationType
                        },
                      })
                    );
                    setCookies(
                      "EmployeeDetails",
                      JSON.stringify({
                        ...user,
                        EmployerID,
                        RoleID,
                        RoleName,
                        EmployerName,
                        singleLogin,
                        FirstName,
                        LastName,
                        HealthCheckToolDisable,
                        EnableInvestmentAdvice,
                        EnableMortgageService,
                        EnableWillService,
                        EnableAvcwise,
                        EnableWealthify,
                        PrivacyPolicyDocument,
                        EnableSnoop,
                        SubDomain,
                        OrganisationType
                      })
                    );
                    dispatch(setLoggingInStatus({ isLoggingIn: false }));
                  } else {
                    dispatch(setLoggingInStatus({ isLoggingIn: false }));
                  }
                } else {
                  let savedUser = getUser;
                  dispatch(
                    setAuthRole({
                      isLoggedIn: true,
                      isInitialized: true,
                      FirstName: savedUser.FirstName,
                      LastName: savedUser.LastName,
                      user: {
                        ...user,
                        EmployerID: savedUser.EmployerID,
                        RoleID: savedUser.RoleID,
                        RoleName: savedUser.RoleName,
                        EmployerName: savedUser.EmployerName,
                        singleLogin: savedUser.singleLogin,
                        HealthCheckToolDisable: savedUser.HealthCheckToolDisable,
                        EnableInvestmentAdvice: savedUser.EnableInvestmentAdvice,
                        EnableMortgageService: savedUser.EnableMortgageService,
                        EnableWillService: savedUser.EnableWillService,
                        EnableWealthify: savedUser.EnableWealthify,
                        EnableAvcwise: savedUser.EnableAvcwise,
                        PrivacyPolicyDocument: savedUser.PrivacyPolicyDocument,
                        EnableSnoop: savedUser.EnableSnoop,
                        SubDomain: savedUser.SubDomain,
                        OrganisationType: savedUser.OrganisationType,
                      },
                    })
                  );
                  employerId = savedUser.EmployerID;
                  roleId = savedUser.RoleID;
                  subDomain = savedUser.SubDomain;
                  dispatch(setLoggingInStatus({ isLoggingIn: false }));
                }

                checkEmployerSubDomain(subDomain as string, roleId, employerId);

              } else {
                dispatch(setLoggingInStatus({ isLoggingIn: false }));
              }
            } else {
              dispatch(setlogout());
              removeCookie("EmployeeDetails");
              localStorage.removeItem("params");
              setParams(null);
              localStorage.removeItem("isConsentDialogShown");
              dispatch(setLoggingInStatus({ isLoggingIn: false }));
            }
          } else {
            dispatch(setLoggingInStatus({ isLoggingIn: false }));
          }
        } catch (err) {
          captureSentryError(err, params ?? "")
          if (params === "true")
            authLogout({ logoutParams: { returnTo: window.location.origin } });
          else
            authLogout({ logoutParams: { returnTo: `${employerSubDomain}/users/sign_out?logout=true` } });

          dispatch(setlogout());
          removeCookie("EmployeeDetails");
          if (state.user?.SubDomain) {
            setCookies('isLoggedOut', true)
          }
          localStorage.removeItem("params");
          setParams(null);
          localStorage.removeItem("isConsentDialogShown");
          dispatch(setLoggingInStatus({ isLoggingIn: false }));
          console.log("auth error", err);
        }
      }
    };
    console.log(`isAuthenticated: ${isAuthenticated}, isLoading: ${isLoading}, user: ${user}, authError: ${authError}`)
    if (!state.isLoggingOut) {
      init();
    }
  }, [fetch, getAccessTokenSilently, isAuthenticated, isLoading,user, logout, error, authLogout, authError, state.isLoggingOut]);

  const login = async (options?: KeyedObject) => {
    try {
      dispatch(setLoggingInStatus({ isLoggingIn: true }));
      await loginWithRedirect(options);
      const isLoggedIn = isAuthenticated;

      if (isLoggedIn) {
        const token = await getAccessTokenSilently();
        const newState = {
          isLoggedIn: true,
          token,
          user: {
            id: user?.sub,
            avatar: user?.picture,
            email: user?.email,
            name: user?.name,
            tier: "Premium",
          },
        };
        dispatch(setLogin(newState));
        const { data } = await fetch("");
        if (data?.data.singleLogin) {
          const Employer = data?.data.organisation[0];
          const EmployerID = Employer.EmployerID;
          const EmployerName = Employer.EmployerName;
          const RoleID = Employer.Roles[0].MMRoleID;
          const RoleName = Employer.Roles[0].RoleName;
          const singleLogin = data?.data.singleLogin;
          const FirstName = data?.data?.FirstName;
          const LastName = data?.data?.LastName;
          const HealthCheckToolDisable = Boolean(Employer?.HealthCheckToolDisable);
          const EnableInvestmentAdvice = Boolean(Employer?.EnableInvestmentAdvice);
          const EnableMortgageService = Boolean(Employer?.EnableMortgageService)
          const EnableWillService = Boolean(Employer?.EnableWillService)
          const EnableWealthify = Boolean(Employer?.EnableWealthify)
          const EnableAvcwise = Boolean(Employer?.EnableAvcwise)
          const EnableSnoop = Boolean(Employer?.EnableSnoop);
          const PrivacyPolicyDocument = Boolean(Employer?.PrivacyPolicyDocument);
          const SubDomain = Employer?.SubDomain as string;
          const OrganisationType = Employer?.OrganisationType;
          dispatch(
            setAuthRole({
              isLoggedIn: true,
              isInitialized: true,
              FirstName: FirstName,
              LastName: LastName,
              user: {
                ...user,
                EmployerID,
                RoleID,
                RoleName,
                EmployerName,
                singleLogin,
                HealthCheckToolDisable,
                EnableInvestmentAdvice,
                EnableMortgageService,
                EnableWillService,
                EnableWealthify,
                EnableAvcwise,
                PrivacyPolicyDocument,
                EnableSnoop,
                SubDomain,
                OrganisationType,
              },
            })
          );
          setCookies(
            "EmployeeDetails",
            JSON.stringify({
              ...user,
              EmployerID,
              RoleID,
              RoleName,
              EmployerName,
              singleLogin,
              FirstName,
              LastName,
              HealthCheckToolDisable,
              EnableInvestmentAdvice,
              EnableMortgageService,
              EnableWillService,
              EnableAvcwise,
              EnableWealthify,
              PrivacyPolicyDocument,
              EnableSnoop,
              SubDomain,
              OrganisationType,
            })
          );
          dispatch(setLoggingInStatus({ isLoggingIn: false }));
        } else {
          dispatch(setLoggingInStatus({ isLoggingIn: false }));
        }
      } else {  
        dispatch(setLoggingInStatus({ isLoggingIn: false }));
      }
    } catch (error) {
      captureSentryError(error, params ?? "")
      console.log("error", error);
      dispatch(setLoggingInStatus({ isLoggingIn: false }));
    }
  };
  const resetPassword = async (email: string) => { };

  const updateProfile = () => { };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Auth0Context.Provider
      value={{
        ...state,
        isLoading,
        setRole,
        error,
        login,
        logout,
        resetPassword,
        updateProfile,
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};

export default Auth0Context;
