import { Navigate, useLocation } from "react-router-dom";

import useAuth from "../../hooks/useAuth";
import Loader from "components/Loader";

import { GuardProps } from "../../types/auth";
import RoleGuard from "./RoleGuard";
import { EMPLOYEE_APP_DEFAULT_PATH, ADMIN_APP_DEFAULT_PATH } from "config";

const AdminRoleGuard = ({ children }: GuardProps) => {
  const { user, isLoggingOut, isLoggingIn, isLoggedIn } = useAuth();
  const location = useLocation();

  if (isLoggingOut || isLoggingIn) {
    return <Loader />;
  }

  console.log("Loctaion Path", location?.pathname);

  if (user?.RoleName !== "Admin" && isLoggedIn) {
    return <Navigate to={EMPLOYEE_APP_DEFAULT_PATH} replace />;
  } else if (isLoggedIn && user?.OrganisationType === "NHS" && location?.pathname == "/admin/organisationProfile"){
    return <Navigate to={ADMIN_APP_DEFAULT_PATH} replace />;
  } else {
    return <RoleGuard>{children}</RoleGuard>;
  }
};

export default AdminRoleGuard;
