const PRODUCTS = {
  SNOOP: "snoop",
  INVESTMENT_ADVICE: "investment advice",
  MORTGAGE_SERVICE: "mortgage services",
  WILLSERVICE: "will services",
  AVCWISE: "avcwise",
  WEALTHIFY: "wealthify",
  NHS: "nhs"
};

const PRODUCT_MAPPING: any = {
  "snoop": "Snoop",
  "investmentadvice": "Investment Advice",
  "avcwise": "AVCWISE",
  "mortgage": "Mortgage services",
  "will": "Will services",
  "wealthify": "Wealthify",
  "nudge": "Nudge",
  "nhs": "NHS"
}

export { PRODUCTS, PRODUCT_MAPPING }